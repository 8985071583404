<template>
  <b-table
    responsive="sm"
    :fields="fields"
    :items="dataList"
    striped
    small
  >
    <template #cell(title)="data">
      <div class="d-flex align-items-center">
        <div class="mr-1">
          <b-button
            pill
            class="btn-icon"
            variant="flat-success"
            :to="'/app/catalogs/view/' + data.item.id"
          >
            <FeatherIcon icon="EyeIcon" />
          </b-button>
        </div>
        <div>
          <div>
            {{ data.item.title }}
          </div>
        </div>
      </div>
    </template>
    <template #cell(expiry_date)="data">
      <div>
        {{ moment(data.item.expiry_date).format('LL') }}
      </div>
    </template>
    <template #cell(catalog_status)="data">
      <div>
        {{ data.item.catalog_status }}
      </div>
    </template>
    <template #cell(username)="data">
      <div v-if="data.item.username">
        {{ data.item.username }}
      </div>
    </template>
  </b-table>
</template>
<script>
import { BTable, BButton } from 'bootstrap-vue'

export default {
  name: 'Catalogs',
  components: {
    BTable,
    BButton,
  },
  props: {
    dataList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'title',
          label: 'KATALOG',
        },
        {
          key: 'expiry_date',
          label: 'GEÇERLİLİK TARİHİ',
        },
        {
          key: 'catalog_status',
          label: 'DURUM',
        },
        {
          key: 'username',
          label: 'TEMSİLCİ',
        },
      ],
    }
  },
}
</script>
